import { Fragment } from 'react';
import PropTypes from 'prop-types';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';
import { dateFormat } from 'core/utils/dates';

import { buildRubricUrl } from 'site/utils';

const requiredPayloadImports = [
  'image',
  'search_result',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  search_result: {
    title: '',
    body: '',
  },
  rubric: {},
});

function CardSearch(props) {
  const {
    content,
    isMobile,
    theme,
  } = props;


  const {
    link,
    headline,
    list_headline: listHeadline,
    announce,
    published_at: published,
  } = content.attributes;

  const date = dateFormat(published, 'dd MMMM yyyy');

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
    },
  } = relationships(content);

  return (
    <Fragment>
      <style jsx>{`
        .content
          // display flex
          align-items flex-start
          overflow hidden

        .rubric
          color #cacaca
          text-transform uppercase

        .header
          display flex
          align-items baseline

        .image
          float right
          border-radius 5px
          overflow hidden
          flex-shrink 0
          max-width 50%

        .texts
          flex-grow 1

        .time
          font-size 14px
          margin-left 15px

        .button
          margin-top 25px
          width 180px

        .headline
          margin-top 10px
          font-size 20px
          font-weight bold

        .announce
          margin-top 10px

      `}</style>
      <style jsx>{`
        .rubric
          font bold 14px/1 ${theme.fonts.nuance}
          :global(a):hover &
            color ${theme.colors.active1}

        .time
          font-family ${theme.fonts.nuance}
          color ${theme.colors.hint}

        .headline
          color ${theme.colors.primary}
          font-family ${theme.fonts.nuance}
          transition ${theme.animations.hover}
          & :global(b)
          :global(a):hover &
            color ${theme.colors.active1}

        .announce
          color ${theme.colors.primary}
          & :global(b)
            color ${theme.colors.active1}
            font-weight normal

        .image
          margin-left ${(isMobile ? 10 : 30) + 'px'}

      `}</style>

      <div className='content'>
        { cover && (
          <div className='image'>
            <Link to={link} type='secondary'>
              <Image
                src={cover}
                previewSrc={previewCover}
                height={isMobile ? 80 : 125}
                width={isMobile ? 80 : 125}
              />
            </Link>
          </div>
        )}
        <div className='texts'>
          <div className='header'>
            <Link to={buildRubricUrl(rubricSlug, rubricRoot)} type='secondary'>
              <div className='rubric'>{rubricTitle}</div>
            </Link>
            <time className='time'>{date}</time>
          </div>
          <Link to={link} type='secondary'>
            <div className='headline'>
              <MarkdownWrapper inline>
                {searchTitle || listHeadline || headline}
              </MarkdownWrapper>
            </div>
            <div className='announce'>
              <MarkdownWrapper inline>
                {searchBody || announce}
              </MarkdownWrapper>
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

CardSearch.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = cardHOC(CardSearch);

Card.displayName = 'CardSearch';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardSearch as StorybookComponent };
